@import "src/styles/fonts";
@import "src/styles/colors";
@import "src/styles/breakpoints";

.modalFormBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  max-width: 100%;
  height: auto;
  padding: 20px 15px;
  background-color: #fafbfc;
  border: 1px solid $gray-lines;
  border-radius: 16px;
  font-family: $font-family;

  @media screen and (max-width: $screen-xs) {
    top: 0;
    transform: translate(-50%, 0);
    height: 100%;
    overflow-y: scroll;
    border-radius: 0;
  }

  .modalFormBoxWrapper {
    position: relative;
    padding: 0 15px;
    max-height: calc(100vh - 40px);
    overflow-y: auto;

    > svg {
      position: absolute;
      right: 15px;
    }
  }
}

.notificationsFormTitle {
  font-size: 25px;
  line-height: 1.2;
  color: var(--primary-dark-color);
  margin-bottom: 10px;
}

.notificationsFormSubtitle {
  color: $gray-dark;
  font-size: 15px;
  margin-bottom: 10px;
}

p {
  margin-bottom: 15px;
}

.getNotificationsButton {
  svg {
    margin-right: 5px;
  }
}

.notificationsForm {
  margin-top: 10px;

  @media screen and (max-width: $screen-xs) {
    padding-bottom: 20px;
  }
}

.notificationMessageIconWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  margin-bottom: 15px;
  border-radius: 50%;
  background-color: var(--primary-lighter-color);
  opacity: 0.5;

  .notificationMessageIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--primary-lightest-color);

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.notificationsFormButtonsWrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  div {
    display: flex;

    .notifcationsCancelButton {
      margin-right: 20px;
    }
  }
}

.formGroup {
  width: 100%;
  margin-top: 5px;

  span {
    color: $gray-dark;
    font-size: 14px;
    margin-bottom: 5px;
  }

  label {
    display: flex;
    flex-direction: column;
    margin: 0;
    color: $gray-dark;
    font-size: $font-size-xxs;
    overflow: unset;

    input {
      display: block;
      width: 100%;
      margin-top: 5px;
      padding: 8px 15px;
      font-size: $font-size-xxs;
      background: $white;
      border: 1px solid $gray;
      border-radius: 8px;
    }

    > div {
      &:hover:not(.Mui-disabled) {
        &:after,
        &:before {
          border: none !important;
        }
      }

      &:after,
      &:before {
        border: none !important;
      }
    }
  }
}

.checkboxGroup {
  label {
    margin: 0;
    color: $gray-dark;
    font-size: $font-size-xxs;

    > span {
      color: var(--primary-color) !important;
    }
  }
}

.radioGroup {
  margin-bottom: 20px;

  label {
    margin: 0;
    color: $gray-dark;

    > span {
      color: var(--primary-color) !important;
      font-size: 14px;
    }
  }
}

.termsCondtionsCheckbox {
  margin-top: 20px;
}

.submitError {
  font-size: $font-size-xxxs;
  color: $red;
  height: 13px;
  margin: 5px 0;
}

.circularProgress {
  margin-left: 5px;
}

.countryListDropdown {
  li {
    padding: 10px !important;

    input {
      margin-top: 0 !important;
      margin-left: 0 !important;
    }
  }
}

.phoneNumberInput {
  display: block !important;
  width: 100% !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-right: 15px !important;
  font-size: $font-size-xxs !important;
  background: $white !important;
  border: 1px solid $gray !important;
  border-radius: 8px !important;
  height: auto !important;
  line-height: 0 !important;
}

.termsAndConditions {
  color: var(--primary-dark-color);
}

.checkboxDisabled {
  opacity: 0.7;
  cursor: not-allowed;
}
